import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { MONITORED_AMMS, MonitoredAmm, REFRESH_INTERVALS, strategiesTable } from 'constants/constants';
import React, { useState } from 'react';
import { ContentTableWrapper } from './app.style';
import { RebalancingStrategies } from 'common/models/strategies';
import { listStrategiesQuery } from 'graphql/queries/listStrategies.query';
import StrategiesTable from 'common/components/StrategiesTable/StrategiesTable';
import ChainSelector from 'common/components/ChainSelector';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { listFarmsQuery } from 'graphql/queries/listFarms.query';
import { listMonitorVaultsQuery } from 'graphql/queries/listMonitorVaults.query';

type Props = {
  pausedOnly?: boolean;
};

const StrategiesContainer: React.FC<Props> = (props) => {
  const [tab, setTab] = useState<MonitoredAmm>(MONITORED_AMMS[0].amms[0]);
  const [criterion, setCriterion] = useState<string | undefined>(undefined);
  const {
    loading: loading,
    error: errorLoading,
    data: data,
    networkStatus: networStatus,
    refetch: refetch
  } = useQuery<QueryItemsResponse<RebalancingStrategies>>(listStrategiesQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listStrategies]
  });

  const {
    loading: loadingFarms,
    error: errorLoadingFarms,
    data: dataFarms,
    networkStatus: networStatusFarms,
    refetch: refetchFarms
  } = useQuery<QueryItemsResponse<AppSyncFarm>>(listFarmsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listFarms]
  });
  const { 
    loading: loadingVaults, 
    error, 
    data: dataVaults 
  } = useQuery<QueryItemsResponse<MonitorVaults>>(listMonitorVaultsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listMonitorVaults]
  });
  
  const strategies: RebalancingStrategies[] = data?.listStrategies?.items || [];
  const vaults: MonitorVaults[] = dataVaults?.listMonitorVaults?.items || [];
  const farms: AppSyncFarm[] = dataFarms?.listFarms.items || [];

  const table = strategiesTable;

  if (errorLoading) {
    console.error(errorLoading);
  }

  return (
    <SpinnerContainer isLoading={loading}>
      <ChainSelector 
        handleClick={(tabName: MonitoredAmm) => setTab(tabName)} 
        currAmm={tab} 
        isHidden={props.pausedOnly}
      />
      <ContentTableWrapper>
        <StrategiesTable 
          table={table} 
          strategies={strategies}
          vaults={vaults}
          farms={farms}
          filter={tab} 
          handleSorting={(sortBy: string | undefined) => setCriterion(sortBy)}
          criterion={criterion} 
          pausedOnly={props.pausedOnly}
        />
      </ContentTableWrapper>
    </SpinnerContainer>
  );
};

export default StrategiesContainer;
